<template>
  <div class="index">
    <!-- 第一页 -->
    <div class="index-item">
      <swiper ref="mySwiper" :options="swiperOption">
        <!-- banner1 -->
        <swiper-slide>
          <div class="index-item1">
            <img
              src="./../assets/images/banner3.jpg"
              style="height: 100vh; width: 100%"
            >
            <div class="item-content">
              <div class="item-title" style="letter-spacing: 20px; font-weight:600;color: #fff;font-size: 66px; padding-left:35px; margin-top：-20px;">
                <div class="title-img">
                  <img src="./../assets/images/33.png">
                </div>
                便捷高效&nbsp;&nbsp;互联互通
              </div>
              <div class="item-text">
                <span style="text-transform: uppercase; font-size:30px; font-weight:bold; margin:0;">&nbsp;&nbsp;Convenient and efficient & Interconnection</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- banner2 -->
        <swiper-slide>
          <div class="index-item1">
            <img
              src="./../assets/images/banner1.jpg"
              style="height: 100vh; width: 100%"
            >
            <div class="item-content">
              <div class="item-title">
                <div class="title-img">
                  <img src="./../assets/images/11.png">
                </div>
                <span>智慧模式供应链</span>
                <span>精细营运新业态</span>
              </div>
              <div class="item-text">
                <span>智能映像代表山水物联蒸蒸日上的发展宏图，建材网络销售运营的创新高地</span>
                <span>也象征产业升级背景下精细的高效服务平台</span>
              </div>
              <div class="item-detile" @click="jumpCompany">了解详情</div>
            </div>
          </div>
        </swiper-slide>
        <!-- banner3 -->
        <swiper-slide>
          <div class="index-item1">
            <img
              src="./../assets/images/banner2.jpg"
              style="height: 100vh; width: 100%"
            >
            <div class="item-content">
              <div class="item-title">
                <div class="title-img">
                  <img src="./../assets/images/22.png">
                </div>
                <span>能源资本区域链</span>
                <span>高屋建瓴数据盘</span>
              </div>
              <div class="item-text">
                <span>标识凸显科技能源和物联产业链经济，深度刻画建材大数据流通领域的创新发展新思路</span>
                <span>高屋建瓴，实现未来 </span>
              </div>
              <div class="item-detile" @click="jumpCompany">了解详情</div>
            </div>
          </div>
        </swiper-slide>

      </swiper>

      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev" />
      <div class="swiper-button-next" />
      <!-- <div slot="pagination" class="swiper-pagination" /> -->

    </div>
    <!-- 第二页 -->
    <div
      class="index-item-2 index-item"
      :class="{ slideInUp: slideIndex.slideInUp === 1 }"
    >
      <div class="item-content">
        <div class="item-title">
          <span>服务保障</span>
          <span />
        </div>
        <div class="item-service">
          <div class="service-item">
            <div class="service-item-img">
              <img src="./../assets/images/icon1.png">
            </div>
            <p>安全保障</p>
            <p>合同物流 · 阳光透明</p>
          </div>
          <div class="service-item">
            <div class="service-item-img">
              <img src="./../assets/images/icon2.png">
            </div>
            <p>全程跟踪</p>
            <p>便捷派车 · 全程跟踪</p>
          </div>
          <div class="service-item">
            <div class="service-item-img">
              <img src="./../assets/images/icon3.png">
            </div>
            <p>专业客服</p>
            <p>专业客服 · 贴心服务</p>
          </div>
          <div class="service-item">
            <div class="service-item-img">
              <img src="./../assets/images/icon4.png">
            </div>
            <p>无忧售后</p>
            <p>诚信体系 · 保驾护航</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 第三页 -->
    <div
      ref="indexItem3"
      class="index-item-3 index-item"
      :class="{ slideInUpEnd: slideIndex.slideInEnd === 2 }"
    >
      <div class="item-content">
        <div class="item-title">
          <span>合作伙伴</span>
          <span />
        </div>
        <div class="item-partner">
          <!--  -->
          <div class="partner-item">
            <img src="./../assets/images/15.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/14.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/13.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/10.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/9.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/7.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/6.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/5.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/4.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/3.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/2.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/1.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/16.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/17.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/18.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/19.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/20.jpg">
          </div>
          <div class="partner-item">
            <img src="./../assets/images/12.jpg">
          </div>

        </div>
      </div>
      <div class="item-footer">
        <div class="item-content">
          <div class="footer-left">
            <div class="copyright">
              版权所有© &nbsp;辽宁山水通途物联智能科技有限公司&nbsp;&nbsp;
              |&nbsp;&nbsp; 辽ICP备2021001264号-1&nbsp;&nbsp;
              <!-- <a href="#" style="font-size:12px;padding-left:25px;color:#999;">后台登录</a> -->
            </div>
            <div class="info">
              <div class="info-phone">
                <img src="./../assets/images/f01.png">
                <span>客服电话：</span>
                <span>400-080-0988</span>
              </div>
              <div class="info-address">
                <img src="./../assets/images/f02.png">
                <span>地址：辽宁省沈阳市浑南区上深沟860号国际软件园F8-B408</span>
              </div>
            </div>
          </div>
          <div class="footer-right">
            <img src="./../assets/images/erweima.png">
            <p class="code">
              <span>扫一扫</span>
              <span>下载APP</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showBut" class="next-button" @click="handleNextClick">
      <div v-if="slideIndex.slideInEnd === 2" class="doudong1">
        <i class="arrow up" />
      </div>
      <div
        class="mouse"
        :class="{ 'mouse-active': slideIndex.slideInEnd === 2 }"
      >
        <!-- <img src="./../assets/images/shubiao.png" /> -->
      </div>
      <div v-if="slideIndex.slideInEnd === null" class="doudong2">
        <i class="arrow down" />
      </div>
      <!-- <img class="doudong" src="./../assets/images/shubiaojiantou.png" /> -->
    </div>
  </div>
</template>

<script>
import event from './event'
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'Index',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      slideIndex: {
        slideInUp: null,
        slideInEnd: null
      },
      slidemid: false,
      showBut: true,
      lastTime: 0,
      swiperOption: {
        // 参数选项,显示小点
        pagination: '.swiper-pagination',
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 滑动速度
        speed: 800,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        loop: true
      }
    }
  },

  mounted() {
    // 监听鼠标滚轮事件
    window.addEventListener(
      'mousewheel',
      this.debounce(this.handleScoll, 300),
      true
    ) ||
      window.addEventListener(
        'DOMMouseScroll',
        this.debounce(this.handleScoll, 300),
        false
      )
  },
  methods: {
    jumpCompany() {
      this.$router.push('/baseHome/about')
    },
    // 函数防抖
    debounce(func, wait) {
      let timeout
      return function() {
        const context = this
        const args = arguments
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          func.apply(context, args)
        }, wait)
      }
    },
    // 滚轮翻页
    handleScoll(e) {
      const { slideInEnd, slideInUp } = this.slideIndex
      const direction = e.deltaY > 0 ? 'down' : 'up'
      // console.log(e.deltaY);
      if (direction === 'down' && e.deltaY >= 60) {
        if (slideInUp == null) {
          this.slideIndex.slideInUp = 1
          return
        } else if (slideInEnd == null) {
          this.slideIndex.slideInEnd = 2
          // this.showBut = false;
          event.$emit('onChangNavHeader', this.slideIndex)
          return
        } else {
          return false
        }
      } else {
        if (slideInEnd === 2) {
          this.slideIndex.slideInEnd = null
          event.$emit('onChangNavHeader', this.slideIndex)
          this.slidemid = true
          return
        } else if (this.slidemid === true && slideInEnd == null) {
          this.slideIndex.slideInUp = null
        } else {
          this.slideIndex.slideInUp = null
          this.slideIndex.slideInEnd = null
        }
      }
    },
    // 点击翻页
    handleNextClick() {
      const { slideInEnd, slideInUp } = this.slideIndex
      if (slideInUp == null) {
        this.slideIndex.slideInUp = 1
        return
      }
      if (slideInEnd === null) {
        // 触发自定义事件
        this.slideIndex.slideInEnd = 2
        // this.showBut = false;
        event.$emit('onChangNavHeader', this.slideIndex)
        return
      }
      if (slideInEnd === 2) {
        this.slideIndex.slideInEnd = null
        this.slideIndex.slideInUp = null
        event.$emit('onChangNavHeader', this.slideIndex)
        return
      }
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/scss/base.scss";
@import "./../assets/scss/mixin.scss";
.index {
  height: 100vh;
  overflow: hidden;

  //banner切换左右箭头
  .swiper-button-next:after{ color: #fff; font-size: 50px;}
  .swiper-button-prev:after{ color: #fff; font-size: 50px;}

  .swiper-button-prev{ z-index: 10; display: block; left: 2%;position: absolute; top: 50%; margin-top:-32px;transition: all 0.5s; opacity: .4;}
  .swiper-button-prev:hover{transition: all 0.5s; opacity: .8;}
  .swiper-button-next{ z-index: 10; display: block; right: 2%;position: absolute;  top: 50%;margin-top:-32px;transition: all 0.5s; opacity: .4;}
  .swiper-button-next:hover{transition: all 0.5s; opacity: .8;}

  .index-item {
    position: relative;
    width: 100%;
    height: 100vh;
    .swiper-container {
      height: 100%;
    }
  }
  .index-item1 {
    position: relative;
    .item-content {
      @include flexCol();
      width: 100%;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translateX(-50%);
      .item-title {
        position: relative;
        display: flex;
        align-items: center;
        .title-img {
          position: absolute;
          left: -146px;
        }
        img {
          margin-right: 50px;
        }
        span {
          letter-spacing: 3px;
          color: #fff;
          font-size: 60px;
        }
        span:nth-child(2) {
          display: inline-block;
          margin-right: 35px;
        }
      }
      .item-text {
        @include flexCol();
        margin-top: 50px;
        line-height: 36px;
        color: #fff;
        font-size: 20px;
      }
      .item-detile {
        width: 146px;
        height: 50px;
        border: 4px solid #fff;
        border-radius: 30px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        font-size: 18px;
        color: #fff;
        margin-top: 60px;
        transition: all 0.5s;
        &:hover {
          transition: all 0.5s;
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
  .index-item-2 {
    position: relative;
    z-index: 100;
    background: url("/static/img/page2.jpg") no-repeat;
    background-size: cover;
    transition: all 0.5s;
    &.slideInUp {
      transform: translateY(-100vh);
    }
    .item-content {
      position: relative;
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      .item-title {
        position: absolute;
        left: 0;
        top: 200px;
        span:nth-child(1) {
          font-size: 36px;
          color: #fff;
          font-weight: bolder;
        }
        span:nth-child(2) {
          margin-top: 30px;
          display: block;
          height: 2px;
          width: 40px;
          background-color: #fafafa;
        }
      }
      .item-service {
        width: 100%;
        position: absolute;
        top: 43%;
        display: flex;
        justify-content: space-around;
        .service-item {
          @include flexCol();
          transition: all 0.5s;
          &:hover {
            margin-top: -25px;
          }
          .service-item-img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 1px solid #f3f4f5;
            text-align: center;
            line-height: 150px;
          }

          p:nth-child(2) {
            font-size: 24px;
            color: #fff;
            font-weight: bolder;
            margin: 40px 0 30px 0;
          }
          p:nth-child(3) {
            color: #fff;
            opacity: 0.7;
            font-size: 16px;
          }
        }
      }
    }
  }
  .index-item-3 {
    position: relative;
    z-index: 300;
    background: #fff;
    background-image: url("/static/img/page3.jpg");
    transition: all 0.5s;
    background-size: cover;
    &.slideInUpEnd {
      transform: translateY(-200vh);
    }
    .item-content {
      position: relative;
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      .item-title {
        position: absolute;
        left: 0;
        top: 200px;
        span:nth-child(1) {
          font-size: 36px;
          color: #000;
          font-weight: bolder;
        }
        span:nth-child(2) {
          margin-top: 30px;
          display: block;
          height: 2px;
          width: 40px;
          background-color: #0062db;
        }
      }
      .item-partner {
        width: 100%;
        position: absolute;
        top: 35%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .partner-item {
          transition: all 0.4s;
          width: 206px;
          height: 112px;
          margin-bottom: 34px;
          border-radius: 6px;
          background-color: #fff;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
          img {
            width: 100%;
            // height: 100%;
            border-radius: 10px;
            vertical-align: middle;
            text-align: center;
          }
          &:hover {
            margin-top: -14px;
          }
        }
      }
    }
    .item-footer {
      position: absolute;
      bottom: 0;
      height: 100px;
      width: 100%;
      background-color: #ffffff73;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      .item-content {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666;
        .footer-left {
          .copyright {
            font-size: 14px;
            margin-bottom: 16px;
          }
          .info {
            display: flex;
            align-items: center;
            .info-phone {
                font-size: 14px;
              margin-right: 35px;
              img {
                margin-right: 10px;
              }
              span:nth-child(3) {
                font-weight: bold;
                font-size: 20px;
              }
            }
            .info-address {
              font-size: 14px;
              img {
                margin-right: 10px;
              }
            }
          }
        }
        .footer-right {
          display: flex;
          align-items: center;
          .code {
            @include flexCol();
            font-size: 14px;
            margin-left: 10px;
            span {
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .next-button {
    @include flexCol();
    position: fixed;
    z-index: 9999;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    cursor: pointer;
    .mouse {
      background: url("./../assets/images/shubiao.png");
      width: 23px;
      height: 37px;
      background-size: cover;
      margin: 10px 0;
      &.mouse-active {
        background: url("./../assets/images/shubiao2.png");
      }
    }

    @keyframes doudong1 {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(0, 4px);
      }
      100% {
        transform: translate(0, 6px);
      }
    }
    @keyframes doudong2 {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(0, -4px);
      }
      100% {
        transform: translate(0, -8px);
      }
    }
    .doudong1 {
      animation: doudong1 1s infinite;
      // animation-fill-mode: both;
      & .up {
        border: solid #003a8b;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 5px;
      }
    }
    .doudong2 {
      animation: doudong2 1s infinite;
    }
  }
}
</style>
